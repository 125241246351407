<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!apierror">
            <van-nav-bar :title="item.title" left-arrow @click-left="returnpev" />
            <van-swipe :autoplay="3000">
              <van-swipe-item v-for="(image, index) in [item.coverimg]" :key="index">
                <img :src="image" fit="cover" width="100%" height="300" />
              </van-swipe-item>
            </van-swipe>
            <div class="item">
              <h2>{{ item.title }}</h2>
              <h4 class="now_price" v-if="item.sale_price > 0">
                {{ $t("currey") }} {{ item.sale_price }}
                <sup>{{ item.price }}</sup>
                / {{ item.stock }}
                <span v-if="item.unity == 0">ML</span>
                <span v-else-if="item.unity == 1">CC</span>
                <span v-else-if="item.unity == 3">{{ $t("detail.unity.type3") }}</span>
                <span v-else-if="item.unity == 4">{{ $t("detail.unity.type4") }}</span>
              </h4>
              <h4 class="now_price" v-else>
                {{ $t("currey") }} {{ item.price }} / {{ item.stock }}
                <span v-if="item.unity == 0">ML</span>
                <span v-else-if="item.unity == 1">CC</span>
                <span v-else-if="item.unity == 3">{{ $t("detail.unity.type3") }}</span>
                <span v-else-if="item.unity == 4">{{ $t("detail.unity.type4") }}</span>
              </h4>
            </div>
            <div class="description" v-if="!item.html">
              {{ item.description }}
            </div>
            <div class="description" v-else v-html="item.html"></div>
            <div v-if="device.nostock && device.beermode==1">
              <van-submit-bar :loading="paymentloading" :price="price" :label="$t('detail.totalprice')"
                :currency="$t('currey')" v-if="parseInt(item.stocka) >= parseInt(item.stock) && device.idkey != '7600'"
                :button-text="$t('detail.makeorder')" @submit="onSubmit" />
              <van-submit-bar :price="price" :button-text="$t('detail.makeorder')" :label="$t('detail.totalprice')"
                :currency="$t('currey')"
                v-else-if="parseInt(item.stocka) >= parseInt(item.stock) && device.idkey == '7600'"
                @submit="onSubmit">

                <template #tip>
                  <!-- 在这里放置 van-stepper 组件 -->
                  <div class="stepper-container">
                    <span class="stepper-label">{{ $t("detail.buynumber") }}</span>
                    <van-stepper v-model="number" @change="onQuantityChange" />
                  </div>

                </template>
              </van-submit-bar>
              <van-submit-bar :loading="paymentloading" :price="price" :label="$t('detail.totalprice')"
                :currency="$t('currey')" v-else :button-text="$t('detail.makeorder')" @submit="onSubmit" disabled
                :tip="$t('detail.nostock')">
              </van-submit-bar>
            </div>
            <div v-else-if="!device.nostock && device.beermode==1">
              <van-submit-bar :loading="paymentloading" :price="price" :label="$t('detail.totalprice')"
                :currency="$t('currey')" :button-text="$t('detail.makeorder')" @submit="onSubmit" />
            </div>
            <div v-else-if="device.beermode==2">
              <van-submit-bar :price="price" :button-text="$t('detail.makeorder')" :label="$t('detail.totalprice')"
                :currency="$t('currey')" @submit="onSubmit">

                <template #tip>
                  <!-- 在这里放置 van-stepper 组件 -->
                  <div class="stepper-container">
                    <span class="stepper-label">{{ $t("detail.buynumber") }}</span>
                    <van-stepper v-model="number" @change="onQuantityChange" />
                  </div>

                </template>
              </van-submit-bar>
            </div>
          </div>
          <div v-else>
            <van-empty v-if="apierror" image="error" :description="$t('global.deviceapierror')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Starter page component
 */
export default {
  data() {
    return {
      loading: true,
      paymentloading: false,
      item: {},
      device: {},
      price: 0,
      showsku: false,
      sku: {},
      goods: {},
      messageConfig: {},
      apierror: false,
      glass: false,
      number: 1
    };
  },
  mounted() {
    this.getDeilInfo(this.$route.params.id);
  },
  methods: {
    getDeilInfo(id) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(that.apiuri, {
          action: "gethdinfo",
          id: id,
        })
        .then(function (response) {
          that.loading = false;
          that.item = response.data.goods;
          if (
            !that.item ||
            (response.data.device.status != 1 &&
              response.data.device.online != 1)
          ) {
            that.$router.push({
              name: "Home",
              query: { sn: response.data.device.device_sn },
            });
          }
          that.device = response.data.device;
          if (that.item.sale_price > 0) {
            that.price = that.item.sale_price * 100;
          } else {
            that.price = that.item.price * 100;
          }
        })
        .catch(function (error) {
          that.apierror = true;
          console.log(error);
          that.loading = false;
        });
    },
    changenumber(e) {
      console.log(e)
    },
    onClickLeft() {
      console.log("click");
      this.$router.go(-1);
    },
    onSubmit() {
      if (this.device.idkey === "7621") {
        this.$dialog
          .confirm({
            title: this.$t("global.tips"),
            message: this.$t("detail.bagtips"),
          })
          .then(() => {
            this.glass = true;
            this.onBuy();
          })
          .catch(() => {
            this.glass = false;
            this.onBuy();
          });
      } else if (this.device.idkey === "7501") {
        this.$dialog
          .confirm({
            title: this.$t("global.tips"),
            message: this.$t("detail.cuptips"),
          })
          .then(() => {
            this.glass = true;
            this.onBuy();
          })
          .catch(() => {
            this.glass = false;
            this.onBuy();
          });
      } else {
        this.onBuy();
      }
    },
    onQuantityChange(value) {
      // 当数量改变时，更新总价格等
      if (this.item.sale_price > 0) {
        this.price = this.item.sale_price * 100;
      } else {
        this.price = this.item.price * 100;
      }
      this.price = this.price * value;
    },
    onBuy() {
      var that = this;
      that.paymentloading = true;
      that.$axios
        .post(that.apiuri, {
          action: "makepreorder",
          hdid: that.item.id,
          number: that.number,
          glass: that.glass,
        })
        .then(function (response) {
          if (response.data.status == 200) {
            that.$router.push({
              name: "cashier",
              params: { id: response.data.orderid },
            });
          }
        });
    },
    returnpev() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.card-body {
  padding: 0;
}

.van-nav-bar .van-icon {
  color: #000;
}

.item {
  padding-left: 1rem;
  padding-right: 1rem;
}

.bottom_logo {
  bottom: 3rem;
}

.description {
  padding: 1rem;
}

.now_price {
  font-size: 18px;
  font-weight: 600;
}

.now_price sup {
  font-size: 10px;
  font-weight: 300;
  text-decoration: line-through;
}

.now_price sup::before {
  content: "\00A5";
  margin-right: 1px;
}

.stepper-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* 使内容靠右对齐 */
}

.stepper-label {
  margin-right: 10px;
  /* 在标签和步进器之间添加一些间距 */
}
</style>
